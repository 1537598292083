import React, { useEffect } from 'react';
import './ArtigoI.css';
import img1 from '../../../assets/img/Artigos/Alinguagemquetocaoscoracoes.jpg'
 

import Footer from '../../Footer';
import { colors } from '@material-ui/core';

const A_linguagem_que_toca_os_coracoes = () => {
    useEffect(() => {
        // Envia o evento de visualização de página para o Google Analytics
        window.gtag('config', 'G-T3KBNL1ZLF', {
            page_path: 'https://www.googletagmanager.com/gtag/js?id=G-T3KBNL1ZLF'
        });
    }, []);

    return (
        <div>
            <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>
            <div className="containerDivulgacao">

            <h1 className="titleDivulgacao">
            A linguagem que toca os corações

 
        </h1><br></br>
        <p className="subtitleDivulgacao">
        Livro aborda a comunicação religiosa e o papel atribuído à vontade individual de clérigos e leigos na Idade Média  </p>       <div className="text-contentDivulgacao">
        <p className="highlight-letter">
       
        </p>
</div>
 
                    <div className="contentDivulgacao">
                        <div className="text-contentDivulgacao">
                        <div className="book-image">
                    <img src={img1} alt="Capa do Livro"
                        style={{ width: '100%', maxWidth: '800px', height: 'auto' }}
                    />
                      <div className="book-details">
          <p>Cativar as almas. Diretrizes para a instrução espiritual (séculos XII e XV)<br />
                                Jean-Claude Schmitt, Leandro Alves Teodoro e Pablo Martín Prieto<br />
                                Editora Unisinos | 132p.</p>
        </div>
                </div>
                           

                <p className="highlight-letter">

                                De que forma a mensagem cristã no período que conhecemos como Idade Média deveria sensibilizar os corações daqueles que a recebiam? Como os leigos, no seu universo cotidiano, aderiram e reproduziram as palavras, os gestos e os conselhos transmitidos por sacerdotes nas missas, nas confissões e nas pregações, bem como em outras circunstâncias sociais? Até que ponto a vontade individual de clérigos e de leigos interferiu na evangelização e na conversão entre os séculos XII e XIII, assim como na percepção de seus pecados e os de seu grupo?
                            </p>

                            <p className="paragraph-content">
                                Essas e outras questões norteiam o livro <em>Cativar as almas. Diretrizes para a instrução espiritual (séculos XII e XV)</em>, de autoria de Jean-Claude Schmitt – professor emérito da Escola de Altos Estudos em Ciências Sociais <em>(École des Hautes Études en Sciences Sociales)</em>, da França –, Leandro Alves Teodoro – professor livre-docente de História Medieval da Universidade Estadual Paulista (UNESP – Câmpus de Assis) – e Pablo Martín Prieto – professor titular de História Medieval da <em>Universidad Complutense de Madrid</em> (Espanha). A coletânea, composta de três capítulos, busca refletir, principalmente, sobre as vontades individuais na cultura religiosa da Europa na Idade Média. “A proposta deste livro consiste em interrogar a força atribuída ao indivíduo no processo de evangelização, com ênfase nas escolhas pessoais e nas singularidades de certos grupos no processo de naturalização da fé cristã do século XIII ao XV”, declaram Teodoro e Martín Prieto na introdução.
                            </p>

                             

                            <p className="paragraph-content">
                                Publicado em 2022, pela Editora Unisinos, <em>Cativar as almas</em> é fruto de um subsídio da Fundação de Amparo à Pesquisa do Estado de São Paulo (FAPESP) reservado à publicação de livros no país, na modalidade Publicações – Livros no Brasil. Ele também é um dos produtos coletivos do projeto “O ensino da fé cristã na península Ibérica (séculos XIV, XV e XVI)”, financiado pela mesma agência de fomento à pesquisa, de outubro de 2018 a setembro de 2024, sob a responsabilidade de Leandro Alves Teodoro. O auxílio, de modalidade Jovem Pesquisador, contribuiu para a criação de uma linha de pesquisa no Estado de São Paulo – destinada ao estudo das formas de fazer crer e das crenças cristãs em terras ibéricas tardo-medievais –, além de possibilitar a realização de vários eventos internacionais, publicações individuais e coletivas, bem como a manutenção de um banco de dados de obras pastorais e doutrinárias, composto de verbetes de autores nacionais e estrangeiros, entre outras atividades.
                            </p>

                            <p className="paragraph-content">
                                Além disso, o livro recebeu apoio do projeto “Instrução e conversão no mundo dos <em>exempla</em> ibéricos: pilares da moral cristã”, coordenado também por Leandro Alves Teodoro, conjuntamente com o filólogo Hugo Oscar Bizzarri, professor de Filologia Hispânica e História da Língua no Departamento de Espanhol da Universidade de Friburgo (Suíça). O auxílio teve início em outubro de 2021 e resulta de um acordo bilateral entre a Fundação de Amparo à Pesquisa do Estado de São Paulo (FAPESP) e o Fundo Nacional Suíço <em>(Fonds National Suisse</em>– FNS). Sua proposta visou à criação de um núcleo de pesquisa internacional e interdisciplinar dedicado ao estudo das funções moralizantes dos relatos breves (<em>exempla</em>), produzidos e compilados na península Ibérica entre os séculos XIII e XVI. Formado por pesquisadores de universidades paulistas (UNESP, Unifesp e USP) e estrangeiras (Universidade de Friburgo – Suíça, Universidade de Genebra – Suíça, Universidade de León – Espanha e Universidade Aberta – Portugal), o grupo já realizou três eventos científicos, além de ter publicado obras compartilhadas e individuais.

                            </p>
                            

                            <p className="paragraph-content">
                                No capítulo que abre o livro, “Práticas espirituais na Idade Média: os limites de uma ‘religião popular’”, Jean-Claude Schmitt, um dos maiores representantes da História Nova e historiador influente na medievística brasileira, discute as condições coletivas e as expressões individuais da “religião vernacular” na cultura medieval, com foco em seus três pilares: a aldeia, o castelo e a cidade. O autor reafirma as críticas ao conceito de “religião popular” – feitas por ele em seus primeiros artigos, na década de 70 do século passado – e propõe a noção de “religião vernacular” – entendida como “modos de expressão e de comunicação de atores sociais fora do meio clerical” (palavras pronunciadas em língua materna; gestos; artefatos; e imagens produzidas e utilizadas). O conceito proposto pelo medievalista se aproxima da de outros historiadores, como a ideia de “cultura intermediária” de Hilário Franco Junior, professor livre-docente aposentado da Universidade de São Paulo (USP) e quem assina o prefácio do livro.

                            </p>

                            <p className="paragraph-content">

No que diz respeito à experiência religiosa individual na cultura do medievo, Schmitt esclarece alguns pontos. Diferente da noção de “pessoa” e da de “indivíduo”, é a ideia de “sujeito” que ele tem em mente, definida como a consciência de si e os modos de manifestar a interioridade, a reflexividade e a intimidade. Outro ponto ressaltado pelo francês é a relatividade histórica da vivência religiosa individual, que difere no tempo e no espaço. “A subjetividade cristã dos homens e mulheres da Idade Média (bem como as noções de indivíduo e pessoa na época) não é a nossa: o sujeito moderno que reivindica sua autonomia se opõe ao sujeito medieval, entendido nos dois sentidos da palavra <em>subjectus</em>”. O sujeito cristão medieval apenas se reconhecia como “sujeito” (leia-se, subordinado) ao “senhor” terrestre e, principalmente, ao “senhor de todos os senhores”, ou seja, Deus.
</p>

                            <p className="paragraph-content">
                                No segundo capítulo, “Frestas para o aparecimento de escritos devocionais em vernáculo”, Leandro Alves Teodoro, com base nas doutrinas transmitidas pela literatura pastoral castelhana dos séculos XIV e XV, mostra que os leigos, longe de terem sido coagidos pelos homens da igreja, eram incentivados e sensibilizados por eles a expressarem suas vontades de modo voluntário, sincero e espontâneo. Isso era esperado tanto em razão da leitura dessas obras (por leigos letrados) como na recepção de seu conteúdo por meio das pregações, missas e outros ofícios administrados pelos clérigos. Por outro lado, os sacerdotes, na condição de mediadores entre Deus e os homens e principais alvos desses escritos, deviam ser eficientes em seus encargos e preparados no cuidado das almas, servindo, ao mesmo tempo, como guias e exemplos aos fiéis. Nesse contexto, Teodoro enfatiza o papel ativo e fundamental tanto de leigos como de eclesiásticos no processo de naturalização das crenças e nas práticas de devoção.

                            </p>
                            <p className="paragraph-content">

                            Ao enfatizar a vontade dos indivíduos de cada <em>ordo</em> (ordem dos leigos e ordem dos clérigos), Teodoro considera, porém, que as escolhas, no universo da cultura religiosa medieval, devem ser compreendidas dentro de um cenário de alinhamento a um projeto divino. Do contrário, as decisões individuais eram interpretadas como insubordinação, como soberba. “Aos olhos dos autores aqui analisados, a vontade individual se converteria em pecado de soberba se o fiel não conformasse seus anseios ao plano divino nem acreditasse nos planos arquitetados por Deus. A liberdade de escolha resumia-se a aprender a gozar dos prazeres da graça e poder se redimir e fazer das próprias tribulações um meio para se fortalecer e contemplar a palavra divina”, argumenta o historiador.
                            </p>
 


                            <p className="paragraph-content">

                            No terceiro e último capítulo do livro, “Sociologia do pecado na literatura penitencial castelhana na Baixa Idade Média: enfoque nas diferentes condições e estados”, Pablo Martín Prieto nos apresenta os pecados relacionados aos grupos sociais e profissionais descritos, principalmente, nos manuais e sumas de confissão, produzidos no reino de Castela tardo-medieval. O autor se centra em figuras que ocuparam degraus mais elevados da sociedade (reis, eclesiásticos e nobres) e naquelas pertencentes a outros estratos sociais, em especial, os ofícios e profissões (camponeses, mercadores e vários outros). 
                            </p>
                            <p className="paragraph-content">

                            Martín Prieto constata que há uma tendência à repetição de estereótipos sobre grupos sociais e profissionais a certos pecados em toda a literatura penitencial e próxima. Não obstante, ao analisar os escritos de nobres letrados – com destaques para Pero López de Ayala (1332-1407) e seu sobrinho Fernán Pérez de Guzmán (1377-1460) –, ele identifica uma autorrepresentação do pecado e da penitência da camada nobiliária, assim como registros da mentalidade individual dos autores que se distanciam dos escritos eclesiásticos que discorrem sobre o mesmo tema. “As possibilidades de colocar em jogo nuances e diferenças de perspectiva entre as mentalidades grupais dos próprios autores aumentam o interesse deste estudo, já que a visão de certos grupos não é a mesma quando quem escreve é um clérigo ou um nobre como Fernán Pérez de Guzmán”, afirma.
                            </p>

                            <p className="paragraph-content">

                            Como o leitor perceberá, os textos reunidos na coletânea se distanciam das interpretações historiográficas que compreendem a mensagem cristã na Idade Média como impositiva ou das que tomam o leigo como um sujeito passivo e irreflexivo. Eles oferecem, portanto, outros caminhos de compreensão da experiência religiosa nesse período. O leitor poderá verificar, ainda, a forma singular pela qual cada autor aborda as fontes históricas examinadas. Schmitt, por exemplo, chama a atenção para os desafios do historiador em localizar vestígios da “religião vernacular” que escapam ao discurso clerical na documentação. Teodoro, por sua vez, critica as leituras que veem a produção pastoral sempre como parte de “uma estrutura ideológica de controle e exploração da terra”, salientando para outras direções de análise com base nos documentos. Por fim, como já sublinhado, Martín Prieto destaca que a concepção sobre dada “realidade” se modificará na documentação penitencial a depender de quem a descreve (se clérigo ou nobre).
                            </p>

                            <p className="paragraph-content">

                            <em>Cativar as almas</em> também estará disponível para os leitores de língua inglesa. A obra será publicada ainda este ano num dossiê do <em>The Mediaeval Journal (TMJ</em>, um importante periódico interdisciplinar e internacional, criado pelo Instituto de Estudos Medievais de St. Andrews (Escócia) e editado pela Brepols. O volume, intitulado <em>Contours of Spiritual Formation from the 13th to the 15th Century</em>, é organizado por Leandro Alves Teodoro, e será formado por quatro artigos de sua autoria, dois artigos de Pablo Martín Prieto e um artigo de Jean-Claude Schmitt.  
                            </p>



                            <div className="content-columns-artigoII">
                                <div className="column-artigoII">
                                    <h3 id='h3-artigoII'>Materiais consultados</h3>
                                    <p>
                                        FRANCO JUNIOR, Hilário. <strong>A Idade média: nascimento do Ocidente</strong>. São Paulo: Brasiliense, 2001.
                                    </p>
                                </div>
                            </div>

                            <div className="content-columns-artigoII">
                                <div className="column-artigoII">
                                    <h3 id='h3-artigoII'>Auxílios</h3>
                                    <p>
                                    Cativar as almas: diretrizes para a instrução espiritual – Sécs. XII-XV. (Processo FAPESP n. 21/10468-6. <strong>Modalidade:</strong> Publicações científicas – Livros no Brasil. <strong>Pesquisador responsável:</strong> Leandro Alves Teodoro (Unesp-Assis).                                    </p>
                                    <p>
                                       O ensino da fé cristã na península Ibérica, sécs. XIV, XV e XVI (Processo FAPESP n. 17/11111-9). <strong>Modalidade:</strong> Jovem Pesquisador. <strong>Pesquisador responsável:</strong> Leandro Alves Teodoro (Unesp-Assis).
                                    </p>
                                    <p>
                                        Instrução e conversão no mundo dos <em>exempla</em> ibéricos: pilares da moral cristã (Processo FAPESP n. 21/02936-0). <strong>Modalidade:</strong>: Convênio. <strong>Pesquisador responsável:</strong>  Leandro Alves Teodoro (Unesp-Assis). <strong>Pesquisador responsável no exterior: </strong> Hugo Oscar Bizzarri (Universidade de Friburgo – Suíça).
                                    </p>
                                </div>
                            </div>
                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/A_linguagem_que_toca_os_coracoes.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>
                            </div>
                            <footer className="footerDivulgacao">
                                <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
                                <p>Março de 2025</p>
                            </footer>
                      
                    </div>
  </div>
  </div>

    
         <Footer />
            </div>
        );
    }


export default A_linguagem_que_toca_os_coracoes;

